import React from 'react'
import classNames from 'classnames/bind'
import { motion, useTransform } from 'framer-motion'

import { ParallaxLayerTypes } from './ParallaxLayersTypes'
import * as s from './ParallaxLayers.module.scss'

const cn = classNames.bind(s)

const _ = {
  x: 0.06,
  y: 0.04,
}

const ParallaxLayerFlat = ({
  x,
  y,
  viewWidth,
  viewHeight,
  intensity = 0.5,
  isAbsolute,
  children,
}: ParallaxLayerTypes) => {
  const translateX = useTransform(x, [0, viewWidth], [viewWidth * _.x * intensity, -viewWidth * _.x * intensity])
  const translateY = useTransform(y, [0, viewHeight], [viewHeight * _.y * intensity, -viewHeight * _.y * intensity])

  return (
    <motion.div
      className={cn('layerWrapper', { isAbsolute })}
      style={{
        translateX,
        translateY,
      }}
    >
      {children}
    </motion.div>
  )
}

export default ParallaxLayerFlat
