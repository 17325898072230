import React, { useEffect, useRef, SyntheticEvent, MouseEvent } from 'react'
import classNames from 'classnames/bind'
import { useWindowSize } from '@react-hook/window-size'

import { TriggerTypes } from './ParallaxLayersTypes'
import * as s from './ParallaxLayers.module.scss'

const cn = classNames.bind(s)

const Trigger = ({ x, y, viewWidth, viewHeight, children, className }: TriggerTypes) => {
  const [width, height] = useWindowSize({ wait: 300 })
  const ref = useRef<HTMLDivElement>(null)
  const rect = useRef<DOMRect>()

  useEffect(() => {
    x.set(viewWidth * 0.5)
    y.set(viewHeight * 0.5)
  }, [x, y, width, height, viewWidth, viewHeight])

  const onActive = () => {
    if (!ref.current) return
    rect.current = ref.current.getBoundingClientRect()
    ref.current.classList.add(cn('isActive'))
  }

  const onMouseMove = (event: MouseEvent) => {
    if (!rect.current) return
    x.set(event.clientX - rect.current.left)
    y.set(event.clientY - rect.current.top)
  }

  const onTouchMove = (event: SyntheticEvent) => {
    if (!rect.current) return
    x.set(event.nativeEvent.targetTouches[0].clientX - rect.current.left)
    y.set(event.nativeEvent.targetTouches[0].clientY - rect.current.top)
  }

  const onIdle = () => {
    x.set(viewWidth * 0.5)
    y.set(viewHeight * 0.5)
    ref.current?.classList.remove(cn('isActive'))
  }

  useEffect(() => {
    if (!ref.current) return
    rect.current = ref.current.getBoundingClientRect()
  }, [ref, width, height])

  return (
    <div
      className={cn('trigger', className)}
      ref={ref}
      onMouseEnter={onActive}
      onTouchStart={onActive}
      onMouseMove={onMouseMove}
      onTouchMove={onTouchMove}
      onMouseLeave={onIdle}
      onTouchEnd={onIdle}
    >
      {children}
    </div>
  )
}

export default Trigger
