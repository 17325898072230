import React, { useEffect, useState, useRef } from 'react'
import classNames from 'classnames/bind'
import { useWindowSize } from '@react-hook/window-size'
import { useSpring } from 'framer-motion'

import useUIContext from 'context/ui'
import ViewportEnter from 'components/core/ViewportEnter'

import Trigger from './Trigger'
import ParallaxLayerFlat from './ParallaxLayerFlat'
import ParallaxLayerText from './ParallaxLayerText'
import FullscreenTrigger from './FullscreenTrigger'
import { ParallaxLayerContentTypes, ParallaxLayersTypes } from './ParallaxLayersTypes'
import * as s from './ParallaxLayers.module.scss'

const cn = classNames.bind(s)

export const ParallaxLayers = ({ type, children, damping = 12, stiffness = 70 }: ParallaxLayersTypes) => {
  const [width, height] = useWindowSize({ wait: 300 })
  const [isInView, setIsInView] = useState(false)
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  const isFontAvailable = useUIContext(s => s.isFontAvailable)
  const ref = useRef<HTMLDivElement>(null)

  const x = useSpring(0, { damping, stiffness })
  const y = useSpring(0, { damping, stiffness })

  useEffect(() => {
    if (!ref.current) return
    setDimensions({
      width,
      height,
    })
  }, [ref, width, height, isInView, isFontAvailable])

  return (
    <>
      <ViewportEnter onEnter={() => setIsInView(true)} onExit={() => setIsInView(false)} once={false}>
        <div className={cn('parallaxLayers', { type })} ref={ref}>
          {React.Children.map(children, (child, i) => {
            if (!child) return null /* Exit early when child is present but falsy */
            const As = child?.props?.isText ? ParallaxLayerText : ParallaxLayerFlat
            return (
              <As
                key={i}
                viewWidth={dimensions.width}
                viewHeight={dimensions.height}
                x={x}
                y={y}
                isAbsolute={!!child?.props?.isAbsolute}
                intensity={child?.props?.intensity}
              >
                {child}
              </As>
            )
          })}
          {type === 'fullscreen' && <Trigger viewWidth={dimensions.width} viewHeight={dimensions.height} x={x} y={y} />}
        </div>
      </ViewportEnter>
      {type === 'twoColumn' && <Trigger viewWidth={dimensions.width} viewHeight={dimensions.height} x={x} y={y} />}
      {/* True "fullsreen" - attaches to window object */}
      {type === 'window' && <FullscreenTrigger x={x} y={y} />}
    </>
  )
}

export const ParallaxLayer = ({ children, isText, textColor, outlineColor }: ParallaxLayerContentTypes) => {
  const bindStyle = isText ? { style: { '--text': textColor, '--outline': outlineColor } as React.CSSProperties } : {}
  return <div {...bindStyle}>{children}</div>
}

export default ParallaxLayers
