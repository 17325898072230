import React from 'react'
import classNames from 'classnames/bind'
import { RichText } from 'prismic-reactjs'

import { htmlSerializerLight } from 'prismic/htmlSerializer'
import { MonumentHeading1 } from 'components/ui/Text'
import { ParallaxLayer, ParallaxLayers } from 'components/ui/ParallaxLayers'
import AppearAnimation from 'components/core/AppearAnimation'
import Container from 'components/core/Container'
import PadlockIcon from 'assets/svg/padlock.react.svg'
import SEO from 'components/core/SEO'
import Spacer from 'components/core/Spacer'
import useCopy from 'lib/useCopy'
import useTheme from 'lib/useTheme'

import { PrivacyPageTypes } from './PrivacyPageTypes'
import * as s from './PrivacyPage.module.scss'
const cn = classNames.bind(s)

const PrivacyPage = ({ data }: PrivacyPageTypes) => {
  useTheme('dark', 'white')
  useCopy(data.prismicSiteSettings?.data)

  if (!data) return null

  const document = data.prismicPrivacyPage

  return (
    <AppearAnimation>
      <>
        <SEO
          title={document?.data.page_meta_title}
          description={document?.data.page_meta_description}
          thumbnail={document?.data.page_meta_thumbnail}
        />

        <Container>
          <div className={cn('wrapper')}>
            <div className={cn('main')}>
              <ParallaxLayers damping={20} stiffness={100} type='window'>
                <ParallaxLayer intensity={1}>
                  <PadlockIcon className={cn('padlock')} />
                </ParallaxLayer>
              </ParallaxLayers>

              <MonumentHeading1 className={cn('title')}>{document?.data?.title?.text || ''}</MonumentHeading1>

              <Spacer initial='1.48vw' medium='1.48vw' />

              <div className={cn('description')}>
                <RichText render={document?.data?.description?.raw} htmlSerializer={htmlSerializerLight()} />
              </div>
            </div>

            <div className={cn('content')}>
              <RichText render={document?.data?.content?.raw} htmlSerializer={htmlSerializerLight()} />
            </div>
          </div>

          <Spacer initial='13.6vw' medium='7.38vw' />
        </Container>
      </>
    </AppearAnimation>
  )
}

export default PrivacyPage
