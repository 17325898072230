import React from 'react'
import classNames from 'classnames/bind'
import { motion, useTransform } from 'framer-motion'

import { ParallaxLayerTypes } from './ParallaxLayersTypes'
import * as s from './ParallaxLayers.module.scss'
const cn = classNames.bind(s)

const _ = {
  xAngle: -22,
  yAngle: -11,
  ampX: 1.1,
  ampY: 1.1,
  y: 0.015,
  x: 0.002,
}

const View = ({ children, viewWidth, x, y, viewHeight, intensity }: ParallaxLayerTypes) => {
  const angleXPrimary = useTransform(y, [0, viewHeight], [-_.xAngle * intensity, _.xAngle * intensity])
  const angleYPrimary = useTransform(x, [0, viewWidth], [_.yAngle * intensity, -_.yAngle * intensity])
  const angleXSecondary = useTransform(
    y,
    [0, viewHeight],
    [-_.xAngle * _.ampX * intensity, _.xAngle * _.ampX * intensity],
  )
  const angleYSecondary = useTransform(
    x,
    [0, viewWidth],
    [_.yAngle * _.ampY * intensity, -_.yAngle * _.ampY * intensity],
  )
  const translateX = useTransform(x, [0, viewWidth], [viewWidth * _.x * intensity, -viewWidth * _.x * intensity])
  const translateY = useTransform(y, [0, viewHeight], [viewHeight * _.y * intensity, -viewHeight * _.y * intensity])
  const scaleX = useTransform(x, [0, viewWidth * 0.5, viewWidth], [1.01, 1.03, 1.01])
  const scaleY = useTransform(y, [0, viewHeight * 0.5, viewHeight], [1.01, 1.03, 1.01])

  return (
    <div className={cn('text')}>
      <motion.div
        className={cn('primary')}
        style={{
          rotateX: angleXPrimary,
          rotateY: angleYPrimary,
        }}
      >
        {children}
      </motion.div>
      <motion.div
        aria-hidden='true'
        className={cn('secondary')}
        style={{
          rotateX: angleXSecondary,
          rotateY: angleYSecondary,
          translateX,
          translateY,
          scaleX,
          scaleY,
          translateZ: -viewHeight * 0.05,
        }}
      >
        <div className={cn('label')}>{children}</div>
      </motion.div>
    </div>
  )
}

export default View
