import React, { useEffect, useCallback } from 'react'
import { useWindowSize } from '@react-hook/window-size'

import { FullscreenTriggerTypes } from './ParallaxLayersTypes'

const FullscreenTrigger = ({ x, y, children }: FullscreenTriggerTypes) => {
  const [width, height] = useWindowSize({ wait: 300 })

  useEffect(() => {
    x.set(width * 0.5)
    y.set(height * 0.5)
  }, [x, y, width, height])

  const onActive = useCallback(() => {
    // ref.current.classList.add(cn('isActive'))
  }, [])

  const onMouseMove = useCallback(
    (event: MouseEvent) => {
      x.set(event.clientX)
      y.set(event.clientY)
    },
    [x, y],
  )

  const onTouchMove = useCallback(
    (event: TouchEvent) => {
      x.set(event.targetTouches[0].clientX)
      y.set(event.targetTouches[0].clientY)
    },
    [x, y],
  )

  const onIdle = useCallback(() => {
    x.set(width * 0.5)
    y.set(height * 0.5)
    // ref.current?.classList.remove(cn('isActive'))
  }, [x, y, height, width])

  // bind to window and body
  useEffect(() => {
    window.addEventListener('mousemove', onMouseMove)
    window.addEventListener('touchmove', onTouchMove)
    document.body.addEventListener('mouseleave', onIdle)
    document.body.addEventListener('mouseenter', onActive)
    return () => {
      window.removeEventListener('mousemove', onMouseMove)
      window.removeEventListener('touchmove', onTouchMove)
      document.body.removeEventListener('mouseleave', onIdle)
      document.body.removeEventListener('mouseenter', onActive)
    }
  }, [width, height, onMouseMove, onTouchMove, onIdle, onActive])

  // return children if exists
  return <>{children}</>
}

export default FullscreenTrigger
