import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import { linkResolver } from 'prismic/linkResolver'
import PrivacyPage from 'views/PrivacyPage'

import { prismicRepo } from '../../prismic-configuration'

export const query = graphql`
  query PrivacyPageQuery($lang: String) {
    prismicPrivacyPage(lang: { eq: $lang }) {
      _previewable
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        title {
          text
        }
        description {
          raw
        }
        content {
          raw
        }
        page_meta_title
        page_meta_description
        page_meta_thumbnail {
          url
          dimensions {
            height
            width
          }
          alt
        }
      }
    }
    prismicSiteSettings(lang: { eq: $lang }) {
      _previewable
      lang
      data {
        scroll_text
        presents
        footer_copyright
        footer_bottom_links {
          footer_bottom_link {
            uid
            type
            lang
          }
          footer_bottom_link_text
        }
        footer_description {
          raw
        }
        footer_logos {
          footer_logo_image {
            gatsbyImageData
            alt
          }
        }
        footer_social_links {
          footer_social_link_icon
          footer_social_link_text
          footer_social_link {
            url
          }
        }
      }
    }
  }
`

export default withPrismicPreview(PrivacyPage, [
  {
    repositoryName: prismicRepo,
    // @ts-ignore
    linkResolver,
  },
])
