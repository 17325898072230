import { useEffect } from 'react'

import useCopyContext, { Copy } from 'context/copy'

export default function useCopy(copy: Copy) {
  const setCopy = useCopyContext(s => s.setCopy)

  useEffect(() => {
    if (copy) setCopy(copy)
  }, [copy, setCopy])
}
