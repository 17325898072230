// extracted by mini-css-extract-plugin
export var colorBlack = "#000";
export var colorGray = "gray";
export var colorDarkGray = "#262626";
export var colorLightGray = "#e5e5e5";
export var colorWhite = "#fff";
export var colorElectricBlue = "#0000fe";
export var colorPastelBlue = "#bbe5fe";
export var colorCandyPink = "#f9709a";
export var colorPastelPink = "#ffcdd2";
export var colorDefaultLime = "#cef564";
export var colorPastelLime = "#eeffbe";
export var colorBlueLagoon = "#9cf0e1";
export var colorPastelLagoon = "#ceffec";
export var colorDefaultLavender = "#8738f6";
export var colorPastelLavender = "#eadcff";
export var colorBrightYellow = "#ffe500";
export var colorDefaultGreen = "#12b968";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var colCount = "6";
export var colWidth = "16.6666666667%";
export var parallaxLayers = "ParallaxLayers-module--parallaxLayers--1ORYg";
export var layerWrapper = "ParallaxLayers-module--layerWrapper--2MBHG";
export var trigger = "ParallaxLayers-module--trigger--3IIFH";
export var isAbsolute = "ParallaxLayers-module--isAbsolute--3-iY4";
export var text = "ParallaxLayers-module--text--2Zj-k";
export var primary = "ParallaxLayers-module--primary--1gVPs";
export var secondary = "ParallaxLayers-module--secondary--3EVeE";
export var label = "ParallaxLayers-module--label--Msogz";